import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";
import { Formik, Field, Form } from "formik";
import { ButtonUI } from "../../common/";

export default function AddUser({
  add_user,
  add_user_modal,
  update_add_user_modal,
  practiceList,
  update_practice_details,
}) {
  const cancelButtonRef = useRef(null);

  async function submitForm(type, values) {
    try {
      const response = await add_user(type, values);
      if (response.message === "User Added") {
        console.log("trying to close modal");
        console.log(response);
        console.log(response.message);

        update_add_user_modal(false);
      }
      if (
        response.message ===
        "Password did not conform with password policy: Password not long enough"
      ) {
        alert(response.message);

        update_add_user_modal(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Transition.Root show={add_user_modal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={update_add_user_modal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <CheckIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Add User
                    </Dialog.Title>
                    <Formik
                      initialValues={{
                        first_name: "",
                        surname: "",
                        email: "",
                        password: "",
                        practice: "",
                      }}
                      onSubmit={async (values) => {
                        await new Promise((resolve) =>
                          setTimeout(resolve, 500)
                        );
                        // alert(JSON.stringify(values, null, 2));
                        submitForm("add", values);
                      }}
                    >
                      <Form className="space-y-6">
                        <div>
                          <label
                            style={{ textAlign: "left" }}
                            className="block text-sm font-medium text-gray-700"
                          >
                            First Name
                          </label>
                          <Field
                            label={"label"}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            name="first_name"
                            type="text"
                          />
                        </div>
                        <div>
                          <label
                            style={{ textAlign: "left" }}
                            className="block text-sm font-medium text-gray-700"
                          >
                            Surname
                          </label>
                          <Field
                            label={"label"}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            name="surname"
                            type="text"
                          />
                        </div>
                        <div>
                          <label
                            style={{ textAlign: "left" }}
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email
                          </label>
                          <Field
                            label={"label"}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            name="email"
                            type="text"
                          />
                        </div>
                        <div>
                          <label
                            style={{ textAlign: "left" }}
                            className="block text-sm font-medium text-gray-700"
                          >
                            Password
                          </label>
                          <div className="mt-1">
                            <Field
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              name="password"
                              type="password"
                            />
                          </div>
                        </div>
                        <Field as="select" name="practice">
                          {practiceList.map((p) => {
                            return <option value={p.id}>{p.name}</option>;
                          })}
                        </Field>

                        {/* <Dropdown
                          type={"practice"}
                          list={practiceList}
                          update={update_practice_details}
                        /> */}

                        <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                          <button
                            type="button"
                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                            onClick={() => update_add_user_modal(false)}
                            ref={cancelButtonRef}
                          >
                            Cancel
                          </button>
                          <ButtonUI type="submit">Add User</ButtonUI>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
