import { API, graphqlOperation, Auth } from "aws-amplify";

export async function listUsers() {
  console.log("trying to list users");
  let nextToken;
  let limit = 10;
  try {
    let apiName = "AdminQueries";
    let path = "/listUsers";
    let myInit = {
      queryStringParameters: {
        limit: limit,
        token: nextToken,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    const { NextToken, ...rest } = await API.get(apiName, path, myInit);
    nextToken = NextToken;
    console.log(rest);
    return rest.Users;
  } catch (error) {
    console.log(error);
  }
}

export async function addUser(type, values) {
  console.log("we are here adding user");
  console.log(values);

  let nextToken;
  var randomstring = Math.random().toString(36).slice(-8);
  try {
    let apiName = "AdminQueries";
    let path = "/addUser";
    let myInit = {
      body: {
        username: values.email,
        practice: values.practice,
        first_name: values.first_name,
        surname: values.surname,
        password: randomstring,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    console.log("myInit");
    console.log(myInit);

    const result = await API.post(apiName, path, myInit);
    console.log(result);

    return result;
  } catch (error) {
    console.log(error);
  }
}

export async function listUsersInGroups() {
  console.log("trying to list users");
  let nextToken;
  let limit = 10;
  try {
    let apiName = "AdminQueries";
    let path = "/listUsersInGroup";
    let myInit = {
      queryStringParameters: {
        groupname: "Admin",
        limit: limit,
        token: nextToken,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    const { NextToken, ...rest } = await API.get(apiName, path, myInit);
    nextToken = NextToken;
    console.log(rest);

    return rest;
  } catch (error) {
    console.log(error);
  }
}

export async function listGroupsForUser(user) {
  console.log("trying to list groups");
  let nextToken;
  let limit = 10;
  try {
    let apiName = "AdminQueries";
    let path = "/listGroupsForUser";
    let myInit = {
      queryStringParameters: {
        username: user.Username,
        limit: limit,
        token: nextToken,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    const { NextToken, ...rest } = await API.get(apiName, path, myInit);
    nextToken = NextToken;
    console.log(rest);

    return rest;
  } catch (error) {
    console.log(error);
  }
}

export async function disableUser(user) {
  console.log("trying to disableUser");
  console.log(user.Username);

  try {
    let apiName = "AdminQueries";
    let path = "/disableUser";
    let myInit = {
      body: {
        username: user.Username,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    const result = await API.post(apiName, path, myInit);
    console.log(result);
    return result;
  } catch (error) {
    console.log(error);
  }
}

export async function enableUser(user) {
  console.log("trying to disableUser");
  console.log(user.Username);

  try {
    let apiName = "AdminQueries";
    let path = "/enableUser";
    let myInit = {
      body: {
        username: user.Username,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    const result = await API.post(apiName, path, myInit);
    console.log(result);
    return result;
  } catch (error) {
    console.log(error);
  }
}

// async function listCognitoUsers(type, values) {
//     console.log("type");
//     console.log(type);

//     console.log("values");
//     console.log(values);

//     try {
//       switch (type) {
//         case "list":
//           // const result = await API.graphql(
//           //   graphqlOperation(listUsersFunction, { type: type, values: values })
//           // );
//           // console.log(result);
//           // const parsed_result = JSON.parse(result.data.listUsersFunction);
//           // console.log(parsed_result.body);
//           // update_users(parsed_result.body[0].Users);
//           break;
//         case "add":
//           console.log("trying to add user");
//           try {
//             const resulst = await API.graphql(
//               graphqlOperation(listUsersFunction, {
//                 type: "add",
//                 values: JSON.stringify(values),
//               })
//             );
//             console.log(resulst);
//           } catch (error) {
//             console.log(error);
//           }
//           break;
//         case "delete":
//           console.log("trying to delete user");
//           try {
//             const resulst = await API.graphql(
//               graphqlOperation(listUsersFunction, {
//                 type: "delete",
//                 values: JSON.stringify(values),
//               })
//             );
//             console.log(resulst);
//           } catch (error) {
//             console.log(error);
//           }
//           break;
//         case "make_admin":
//           console.log("trying to make Admin");
//           try {
//             const resulst = await API.graphql(
//               graphqlOperation(listUsersFunction, {
//                 type: "make_admin",
//                 values: JSON.stringify(values),
//               })
//             );
//             console.log(resulst);
//           } catch (error) {
//             console.log(error);
//           }
//           break;

//         default:
//           break;
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   }
