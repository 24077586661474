import React, { useEffect, useState } from "react";
import _ from "lodash";
import { navigate } from "gatsby";
const people = [
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  // More people...
];

export default function Users({
  users,
  update_add_user_modal,
  practice_list,
  disableUser,
  enableUser,
  listGroupsForUser,
}) {
  function practiceSelected(user) {
    try {
      const practice = _.find(user.Attributes, {
        Name: "custom:Practice",
      }).Value;
      return _.find(practice_list, { id: practice }).name;
    } catch (error) {
      return <div style={{ color: "#bf3030" }}>None selected</div>;
    }
  }

  function returnName(user) {
    console.log(user);
    try {
      const first = _.find(user.Attributes, {
        Name: "custom:first_name",
      }).Value;
      const surname = _.find(user.Attributes, { Name: "custom:surname" }).Value;
      return first + " " + surname;
    } catch (error) {
      return "No Name Selected";
    }
  }

  function returnGroups(groups) {
    return JSON.stringify(groups);
    // try {
    //   if (user.groups.length === 0) {
    //     return (
    //       <span
    //         key={user.Username}
    //         className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
    //       >
    //         Basic
    //       </span>
    //     );
    //   }
    //   const badges = user.groups.map((group) => {
    //     if (group === "Admin") {
    //       return (
    //         <span
    //           key={user.Username + group}
    //           className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800"
    //         >
    //           Admin
    //         </span>
    //       );
    //     }
    //     if (group === "SuperAdmin") {
    //       return (
    //         <span
    //           key={user.Username + group}
    //           className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800"
    //         >
    //           Super Admin
    //         </span>
    //       );
    //     }
    //   });
    //   return <div id={user.Username}>{badges[0]}</div>;
    // } catch (error) {
    //   return (
    //     <span
    //       key={user.Username}
    //       className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
    //     >
    //       Basic
    //     </span>
    //   );
    // }
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Users</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the users in your account including their name, title,
            email and role.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            onClick={() => update_add_user_modal(true)}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Add user
          </button>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Practice
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Enabled?
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Roles
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {users.map((user) => (
                    <tr key={user.Username}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {returnName(user)}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {_.find(user.Attributes, { Name: "email" }).Value}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {practiceSelected(user)}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {user.UserStatus}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {JSON.stringify(user.Enabled)}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {JSON.stringify(typeof user.groups)}
                        {/* {returnGroups(user.groups)} */}
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <a
                          onClick={() =>
                            navigate("/admin/users/edit?id=" + user.Username)
                          }
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          Edit
                          <span className="sr-only">, {user.Username}</span>
                        </a>
                      </td>
                    </tr>
                    //  onClick={() => navigate("/user")}
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
