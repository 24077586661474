import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../components/layout";
import Container from "../../../common/components/container";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { Link } from "gatsby";
import { listUsersFunction } from "../../../graphql/queries";
import { Formik, Field, Form } from "formik";
import { ButtonUI } from "../../../common";
import AddUser from "../../../components/users/addUser";
import { fetchPractices } from "../../../actions";

import {
  listUsers,
  listUsersInGroups,
  addUser,
  disableUser,
  enableUser,
  listGroupsForUser,
} from "../../../functions/admin/users";

import Users from "../../../components/users";

const Index = () => {
  const dispatch = useDispatch();
  const [loading, update_loading] = useState(true);
  const [users, update_users] = useState([]);

  const [add_user_modal, update_add_user_modal] = useState(false);
  const practices = useSelector((state) => state.practices);

  useEffect(() => {
    try {
      //const result = listCognitoUsers("list", "{}");

      fetchData();
    } catch (error) {
      console.log(error);
    }
  }, []);

  async function fetchData() {
    var user_list = [];
    try {
      var initial_user_list = await listUsers();
      initial_user_list.map(async (u, index) => {
        const groups_for_user = await listGroupsForUser(u);
        var group_list = [];
        groups_for_user.Groups.map((g) => {
          group_list.push(g.GroupName);
        });
        initial_user_list[index].groups = group_list;
      });
      user_list = [...initial_user_list];
    } catch (error) {}

    console.log("final list");
    console.log(user_list);
    update_users(user_list);
    update_loading(false);
  }

  useEffect(() => {
    dispatch(fetchPractices());
  }, []);

  function update_practice_details(event) {
    console.log(event);
  }
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <Container>
        <Users
          users={users}
          update_add_user_modal={update_add_user_modal}
          practice_list={practices}
          //userFunction={listCognitoUsers}
          disableUser={disableUser}
          enableUser={enableUser}
          listGroupsForUser={listGroupsForUser}
        />
        <AddUser
          add_user={addUser}
          add_user_modal={add_user_modal}
          update_add_user_modal={update_add_user_modal}
          practiceList={practices}
          update_practice_details={update_practice_details}
        />

        {/* <div>{returnUsers()}</div> */}
      </Container>
    </Layout>
  );
};

export default Index;
